import {
  EyeOutlined,
  FilePdfOutlined,
  MailOutlined,
  PrinterOutlined,
  SearchOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Col, Select, Table, Form, Card, Input, Space } from 'antd';
import { Button } from 'antd';
import { Tooltip } from 'antd';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useAuth from '../core/hooks/useAuth';
import useCupom from '../core/hooks/useCupom';
import { updateUser } from '../core/store/Auth.slice';
import { UserService } from '../sdk';
import { Pedidos } from '../sdk/@types/Pedidos';
import { Usuarios } from '../sdk/@types/Usuarios';
import CupomService from '../sdk/services/Cupom.service';
import PedidosService from '../sdk/services/Pedidos.service';
import ModalCupons from './ModalCupons';
import transformarClientes from './TransformarClientes';
import transformaTabelaCupons from './TransformaTabelaCupons';
import xmlIcon from '../assets/xml-_1_.svg';
import { ColumnProps } from 'antd/lib/table';
import ProdutosService from '../sdk/services/Produtos.service';
import ModalProduto from './ModalProduto';
import CategoriaService from '../sdk/services/Categoria.service';

export default function TabelaProdutos() {
  let { user } = useAuth();
  const dispatch = useDispatch();
  const [lojas, setLojas] = useState<Usuarios.comboLojas[]>();
  const [cupons, setCupons] = useState<Pedidos.tabelaCuponsFiscais[]>();
  const [produtos, setProdutos] = useState<any>();
  const [categorias, setCategorias] = useState<any>();

  const [restauranteAtual, setRestauranteAtual] = useState<Usuarios.ListaRestaurantes>();
  const [restauranteSelecionado, setRestauranteSelecionado] =
    useState<Usuarios.comboLojas>();
  const [cupom, setCupom] = useState<any>();
  const [reload, setReload] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const handleRefreshClick = () => {
    setRefresh(!refresh);
    setLoading(true);
    console.log('Dados atualizados');
  };

  let id = 'MjAyMjAyMjUxMTI4MjBAaG9tb2xvZ2FjYW8=';

  const [error, setError] = useState<Error>();

  const [recarregarTabela, setRecarregarTabela] = useState<boolean>(false);

  const { Option } = Select;

  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const onSearch = (value: string) => {};

  let tokenTechsapp = '';
  useEffect(() => {
    if (user?.grupos?._embedded.grupos[0].id === 1) {
      setLojas(trataLojasUsuario(user));
    }
    if (user?.grupos?._embedded.grupos[0].id === 5) {
      UserService.getAllRestaurantes()
        .then(trataLojas)
        .then(setLojas)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp]);

  function trataLojas(
    lojas: Usuarios.ArrayDeRestaurantesDoUsuario
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    lojas._embedded.restaurantes.map((restaurante) => {
      const restauranteAux: Usuarios.comboLojas = {
        id: restaurante.id,
        index: indexCombo,
        nome: restaurante.nome,
        dominio: restaurante.dominio,
        token: restaurante.basic_token,
        cnpj: restaurante.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    });

    return comboRestaurantes;
  }

  function trataLojasUsuario(
    usuario: Usuarios.UsuarioDetalhadoGrupo
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    if (usuario.restaurantes) {
      const restauranteAux: Usuarios.comboLojas = {
        id: usuario.restaurantes.id,
        index: indexCombo,
        nome: usuario.restaurantes.nome,
        dominio: usuario.restaurantes.dominio,
        token: usuario.restaurantes.basic_token,
        cnpj: usuario.restaurantes.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    }

    return comboRestaurantes;
  }

  function trataRestauranteAtual(
    restaurantes: Usuarios.ListaRestaurantes
  ): Usuarios.ListaRestaurantes {
    if (user && restaurantes) {
      let userAux = {
        grupos: user.grupos,
        usuario: user.usuario,
        restaurantes: restaurantes,
      };
      user = userAux;

      userAux.restaurantes = restaurantes;
      dispatch(updateUser(userAux));
    }
    return restaurantes;
  }
  const onChange = (value: any) => {
    value = JSON.parse(value);

    setRestauranteSelecionado(value as Usuarios.comboLojas);
    UserService.getRestaurantesByID(value.id)
      .then(trataRestauranteAtual)
      .then(setRestauranteAtual);
  };

  function trataNota(dados: any): any {
    return dados;
  }

  const getColumnSearchProps = (
    dataIndex: keyof any,
    displayName?: string
  ): ColumnProps<any> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns: any = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      ...getColumnSearchProps('nome', 'nome'),
    },
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      key: 'categoria',
      ...getColumnSearchProps('categoria', 'categoria'),
      render(categoria: any) {
        if (categoria) {
          return categoria.nome;
        } else {
          return '';
        }
      },
    },

    {
      title: 'Ean',
      dataIndex: 'ean',
      key: 'ean',
      ...getColumnSearchProps('ean', 'ean'),
    },
    {
      title: 'Código de Integração',
      dataIndex: 'codigo',
      key: 'codigo',
      ...getColumnSearchProps('codigo', 'codigo'),
    },
    {
      title: 'Unidade',
      dataIndex: 'unidade',
      key: 'unidade',
      ...getColumnSearchProps('unidade', 'unidade'),
    },
    {
      title: 'Valor',
      dataIndex: 'preco',
      key: 'preco',
      ...getColumnSearchProps('preco', 'preco'),
      render(total: string) {
        var valorAux = 'R$' + parseFloat(total).toFixed(2).replace('.', ',');
        return valorAux;
      },
    },
    {
      title: 'Estoque',
      dataIndex: 'quantidade_estoque',
      key: 'quantidade_estoque',
      ...getColumnSearchProps('unidade', 'unidade'),
    },

    {
      title: 'Ação',
      dataIndex: 'id',
      width: 80,
      key: 'id',
      //fixed: 'right',

      render(id: string) {
        return (
          <>
            <div style={{ display: 'flex', gap: '5px', justifyContent: 'right' }}>
              {user?.restaurantes ? (
                <ModalProduto
                  quandoFinalizar={() => {
                    setReload(!reload);
                  }}
                  produtoId={id}
                  restauranteId={user?.restaurantes.id}
                ></ModalProduto>
              ) : (
                ''
              )}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (user?.restaurantes?.id) {
      setLoading(true);
      ProdutosService.getProdutos(user?.restaurantes?.id as string)
        .then((produtos: any) => {
          console.log(user?.restaurantes?.id);
          console.log(produtos);
          return produtos._embedded.produtos;
        })
        .then(setProdutos)
        .catch((error) => {
          setLoading(false);
          setError(new Error(error.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user?.restaurantes, reload, refresh]);

  useEffect(() => {
    if (user?.restaurantes?.id) {
      setLoading(true);
      CategoriaService.getByIdRestaurante(user?.restaurantes?.id as string)
        .then(setCategorias)
        .catch((error) => {
          setError(new Error(error.message));
        })
        .finally(() => {});
    }
  }, [user?.restaurantes, reload, refresh]);

  function trataProdutos(produtos: any) {
    return produtos._embedded.produtos;
  }

  return (
    <>
      {' '}
      {user?.grupos?._embedded.grupos[0].id == 5 ? (
        <Col xs={24} lg={13}>
          <Form.Item label={'Loja'} name={'loja'} style={{ width: '70%' }}>
            <Select
              placeholder={'Selecione o loja'}
              showSearch
              onChange={onChange}
              onSearch={onSearch}
              optionFilterProp='children'
              defaultValue={lojas ? lojas[0].nome : ''}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {lojas?.map((restaurante) => (
                <Select.Option value={JSON.stringify(restaurante)}>
                  {restaurante.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        ''
      )}
      <Button
        type='primary'
        icon={<ReloadOutlined />}
        onClick={handleRefreshClick}
        style={{ width: '50px' }}
      />
      <Table dataSource={produtos ? produtos : []} columns={columns} loading={loading} />
    </>
  );
}
